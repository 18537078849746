$dark-violet: #18002E;
$violet: #38006A;;
$light-violet: #8338C6;
// $yellow: #F9EE6E;
$yellow: #FFB800;
$pink: #FF3CB2;

@font-face {
    font-family: 'komika_jamregular';
    src: url('../Fonts/komikahb.woff2') format('woff2'),
         url('../Fonts/komikahb.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}