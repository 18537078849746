@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Teko:wght@700&display=swap');

html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 0;
    margin: 0;
    background: $dark-violet;
    color: white;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    overflow-x: hidden;
}

h1{
    font-family: 'Teko', sans-serif;
    color: $yellow;
    text-transform: uppercase;
    font-size: clamp(4rem, -0.875rem + 8.333vw, 7.5rem);
    margin: 0;
    line-height: 1;

    &.white {
        color: white;
    }
}

h2{
    font-family: 'Teko', sans-serif;
    color: $yellow;
    text-transform: uppercase;  
    font-size: clamp(3rem, -0.875rem + 6.333vw, 4.5rem);
    margin: 0;
    line-height: 1;
    word-break: keep-all;
}

h3 {
    font-family: 'Montserrat', sans-serif;
    margin: 1rem 0 0 0;
    font-size: clamp(1.125rem, -0.875rem + 2.333vw, 1.5rem);
    line-height: 1.325;
    word-break: keep-all;
    word-wrap: normal;

    &.Teko {    
        font-family: 'Teko', sans-serif;
        font-size: clamp(2rem, -0.875rem + 6.333vw, 3.5rem);
    }
}

h4{
    font-family: 'Teko', sans-serif;
    // color: $yellow;
    text-transform: uppercase;  
    font-size: clamp(1.5rem, -0.875rem + 3.333vw, 2.25rem);
    margin: 0.75rem 0 0 0;
    line-height: 1;
}

p {
    line-height: 1.5;

    a {
        color: $yellow;
        pointer-events: all;

        &:hover {
            color: white;
        }
    }
}

small {
    text-align: center;
}

.button-outline {
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    color: $yellow;
    border: 2px solid $yellow;
    text-decoration: none;
    padding: 0.65rem 1.5rem;
    display: inline-block;
    margin: 2rem 1rem;
    pointer-events: all;

    &:hover {
        background: $yellow;
        color: $dark-violet;
    }
}

.footer-txt {
    color: rgba(255,255,255,0.5);
}