@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

.memegen {
    background: $violet;
    padding: clamp(1.5rem, -0.875rem + 3.333vw, 3.5rem);

    input[type="text"]{
        display: block;
        width: 100%;
        margin: 1rem 0;
        border: 4px solid $dark-violet;
        background: $dark-violet;
        padding: 0.75rem 1rem 0.75rem 0.5rem;
        color: $yellow;
        pointer-events: all; 
        font-size: 1.25rem;
        box-sizing: border-box;
    }

    button {
        pointer-events: all;
    }

    .punchit {
        pointer-events: all;
        position: relative;
        top: 0px;
        font-family: 'Teko', sans-serif;
        text-decoration: none;
        font-size: 2rem;
        background: red;
        padding: 1rem 3.5rem;
        width: 100%;
        margin-bottom: 2rem;
        // box-sizing: border-box;
        border: 1px solid red;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 5px 0px darkred;
        -moz-box-shadow: 0px 5px 0px darkred;
        -ms-box-shadow: 0px 5px 0px darkred;
        -o-box-shadow: 0px 5px 0px darkred;
        box-shadow: 0px 5px 0px darkred;
        color: white;
        -webkit-transition: All 250ms ease;
        -moz-transition: All 250ms ease;
        -o-transition: All 250ms ease;
        -ms-transition: All 250ms ease;
        transition: All 250ms ease;
        cursor: pointer;

        &:active {        
            position: relative;
            top: 5px;
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            -ms-box-shadow: none !important;
            -o-box-shadow: none !important;
            box-shadow: none !important;
            -webkit-transition: All 250ms ease;
            -moz-transition: All 250ms ease;
            -o-transition: All 250ms ease;
            -ms-transition: All 250ms ease;
            transition: All 250ms ease;
        }

        &:disabled {
            color: #666;
            background: #999;
            border: 1px solid #333;
            -webkit-box-shadow: 0px 5px 0px #222;
            -moz-box-shadow: 0px 5px 0px #222;
            -ms-box-shadow: 0px 5px 0px #222;
            -o-box-shadow: 0px 5px 0px #222;
            box-shadow: 0px 5px 0px #222;
        }
    }

    .download {
        display: block;
        background: none;
        border: none;
        margin: 0 auto;
        color: $yellow;
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        // border: 1px solid $yellow;
        // padding: 0.75rem 2rem;

        span {      
            text-decoration: underline;
        }

        &:hover {
            cursor: pointer;
            // background: $yellow;
        }
    }
}

.memegen {
    .memesmall {      
        display: block;
        margin-top: 1rem;
        text-align: left;
        font-size: 1rem;
    }
}

.meme {
    position: relative;

    .meme-text {
        border: 2px solid #111;
        background: white;
        padding: 0.5rem 1rem;
        // font-family: 'Chewy', cursive;
        font-family: 'komika_jamregular';
        font-size: clamp(0.85rem, -0.875rem + 1.888vw, 1.5rem);
        color: #111;
        position: absolute;
        margin: 0.5rem;
        text-transform: uppercase;
        z-index: 100;
        text-align: left;
        max-width: 70%;
    }

    .meme-img {
        width: 100%;
        padding-top: 100%;
        background: black;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }

    .fter {
        background: $dark-violet;
        font-family: 'Teko', sans-serif;
        padding: 0.25rem 0.5rem;
        font-size: 1.125rem;
        letter-spacing: 1px;
        color: white;
        position: absolute;
        text-transform: uppercase;
        bottom: 0.5rem;
        left: 0.5rem;
    }

    .fteruser {
        background: $dark-violet;
        font-family: 'Teko', sans-serif;
        padding: 0.25rem 0.5rem;
        font-size: 1.125rem;
        letter-spacing: 1px;
        color: white;
        position: absolute;
        text-transform: uppercase;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}