section{
    // min-height: calc(100vh - 4rem);
    overflow: hidden;
    position: relative;
    padding: 4rem 0;
    pointer-events: none;

    &.cover {
        min-height: clamp(50vh, -0.875rem + 50.333vw, 65vh);
        width: 100vw;
        background: url('../Images/pib_cover.png');
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: cover;

        &.mobi {
            background-size: auto 120%;
        }

        // img {
        //     object-fit: cover;
        //     object-position: 50% bottom;
        //     // height: 100%;
        //     width: 100%;
        // }
    }

    &.cover-bottom {
        background-image: url('../Images/pib_cover_bottom.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top center;
        min-height: auto;
        padding-bottom: 4rem;
        padding-top: 0;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            // background: linear-gradient(180deg, rgba(37,29,53,0) 0%, rgba(37,29,53,1) 50%);
            bottom: 0;
            top: 0;
            width: 100vw;
            z-index: 0;
        }
    }
}

.inner {
    text-align: center;

    &-left {
        text-align: left;
    }

    &-right {
        text-align: left;
    }
}

.landing-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    img {
        object-fit: cover;
        object-position: center bottom;
        width: 100%;
        height: 100%;
    }
}

#NFTUtil {
    padding-top: 0;
    margin-top: -2rem;
    // padding-bottom: 0;
}

.utility {
    padding: 1rem 0 2rem 0;
    // color: $pink;

    .util {
        padding: 0 0 1rem;
        // font-size: 2rem;

        img {
            width: clamp(8rem, -0.875rem + 10.333vw, 16rem);
        }
    }

    p {
        margin-top: 0.5rem;
    }
}

.story {
    padding: 3rem 0 0 0;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &.mobi {

        .slider-wrapper {

           &:before {
           }
        }
    }
}

.carousel-root {
    pointer-events: all;

    .thumbs-wrapper{
        display: none;
    }

    .slider-wrapper {
        position: relative;
        padding-bottom: 1rem;

        &:before {
            content: 'The adventures of Rafulu and his journeys of the alternate metaverses...'; 
            font-family: 'komika_jamregular';  
            font-size: clamp(0.75rem, -0.875rem + 1.666vw, 1.5rem);
            font-weight: 100;
            position: absolute;
            background: white;
            border: 2px solid black;
            color: #333;
            bottom: -0;
            right: 1rem;
            left: 1rem;
            z-index: 1000;
            padding: 0.5rem;
            text-align: left;
            letter-spacing: 0.5px;
        }
    }

    .carousel.carousel-slider {
        width: 101% !important;
        // overflow: auto !important;
        padding-bottom: 5rem;
    }

    ul.slider {
        background: white; 

        li {
            padding: 1rem;
            background: #fafafa; 
        }
    }

    .control-dots {
        // bottom: -2rem  !important;

        .dot {
            background: $light-violet !important;
            width: 0.75rem !important;
            height: 0.75rem !important;
            border-radius: 0 !important;
        }
    }
}

.game_bg {
    min-height: 50vh;
    // background-image: url('../Images/game_bg.jpg');
    background-size: cover;
    background-position: center center;
    // margin-bottom: 4rem;
}

.game-level {
    text-align: center;
    font-family: 'Teko', sans-serif;
    font-size: clamp(1.5rem, -0.875rem + 4.333vw, 2rem);
}

.ingame {
    // text-align: center;
}

.gameutil-img {
    // width: clamp(3rem, -0.875rem + 16.333vw, 100%);
    width: clamp(4rem, -0.875rem + 6.333vw, 10rem);
    height: auto;
    margin: 0 auto;
    // outline: 1px solid red;
}

.game-util-txt {
    margin-top: 0rem;
    margin-bottom: 0;
}

.game-util-h3 {
    font-family: 'Teko', sans-serif;
    font-size: clamp(1.5rem, -0.875rem + 4.333vw, 2rem);
    margin-bottom: 0;
    margin-top: 0;
}

.gamecell {
    // border: 8px ridge $violet;
    // border: 1px solid $violet;
}

.ingame {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

hr {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    margin: 1.5rem 0;
}

.progress {
    height: 2.5rem;
    border: 8px solid $yellow;
    font-family: 'Teko', sans-serif;
    color: $yellow;
    font-size: 1.5rem;
    margin: 1rem 0 4rem 0;
    position: relative;
    background: rgba(255,60,178,0.35);
    outline: 4px solid $dark-violet;
    outline-offset: -12px;
    text-align: left;

    &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: -8px;
        width: 8px;
        background: $dark-violet;
        height: 8px;
        z-index: 1000;
    }

    &:after {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        width: 8px;
        background: $dark-violet;
        height: 8px;
        z-index: 1000;
    }

    .bar {
        height: 2rem;
        background: $pink;
        border: 4px solid $dark-violet;
        border-right: none;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: calc(100% - 16px);
            background: rgba(255,255,255, 0.4);
            height: 8px;
            z-index: 100;
        }

        &:after {
            content: '';
            position: absolute;
            top: 12px;
            left: 4px;
            background: rgba(255,255,255, 0.4);
            height: 8px;
            width: 8px;
            z-index: 100;
        }
    }

    .reading {
        display: flex;
        justify-content: space-between;
        position: relative;
    
        &:before {
            content: '';
            position: absolute;
            top: 0px;
            right: -8px;
            width: 8px;
            background: $dark-violet;
            height: 8px;
            z-index: 1000;
        }
    
        &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: -8px;
            width: 8px;
            background: $dark-violet;
            height: 8px;
            z-index: 1000;
        }
    }

    span {
        display: block;
        color: white;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-size: 0.85rem;
        margin-top: 1rem;
    }
}

.team {
    margin-bottom: 2rem;
    
    img {
        width: 100%;
        margin: 0 auto;
    }

    a {
        color: $yellow;
        pointer-events: all;

        &:hover {
            color: white;
        }
    }

    span {
        display: block;
        margin-top: 1rem;
        font-family: 'Teko', sans-serif;
        text-transform: uppercase;
        font-size: 1.5rem;
    }
}

.floating-socials {
    position: fixed;
    z-index: 0;
    left: 1.25rem;
    bottom: 0.5rem;

    > div {    
        display: flex;
        flex-direction: column;
    }

    a {
        background: $dark-violet;
        margin-bottom: 1rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        transition: all 300ms ease-in-out;

        &.disabled {
            pointer-events: none;
        }

        svg {   
            fill: white;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            background: $yellow;
            
            svg {
                fill: $dark-violet;
            }
        }

    }

    
}